<template>
    <div>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employee History</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item" aria-current="page">
                    <router-link :to="{name: 'employees'}">Employees</router-link>
                </li>
                <li class="breadcrumb-item active">History</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_success">
                <b-alert
                :show="alertDismissCountDown"
                dismissible
                variant="success"
                @dismissed="alertDismissCountDown=0"
                @dismiss-count-down="alertCountDownChanged"
                >{{api_success}}</b-alert>
            </div>

            <div v-if="api_error">
                <b-alert
                :show="alertDismissCountDown"
                dismissible
                variant="danger"
                @dismissed="alertDismissCountDown=0"
                @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div v-for="(value, name, index) in items" :key ="index">
                <div class="row" >
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">
                                    <span style="float:right;"><b>Updated At: {{ value.updated_at }}</b></span>
                                    <b>Updated By: {{ value.updated_by_name }}</b>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row mb-10" v-for="(history_value, history_key, history_index) in value" :key ="history_index">
                                    <div class="col-md-4" v-if="!protected_keys.includes(history_key)">
                                        <b>{{ str_title(history_key) }}</b>
                                    </div>
                                    <div class="col-md-6" v-if="!protected_keys.includes(history_key)">
                                        : {{ history_value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>    
        </div>
    </div>
</template>

<script>
import default_error from "../../../assets/js/global";
export default {
    data() {
        return {
            api_error: "",
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            api_success: "",
            items: [],
            protected_keys: ['updated_at','updated_by','updated_by_name'],
        };
    },
    mounted() {
        this.getEmployees();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        getEmployees: function() {
            this.startProgressBar();
            let data = {
                id: this.$route.params.id
            }
            var query = this.getAuthHeaders();
            query["params"] = data;
            this.axios.get(this.$api.get_employees, query).then(response => {
                const result = response.data.data;
                this.items = JSON.parse(result.history);
                this.api_error = "";
                this.updateProgressBar(true);
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
        capitalizeFirstLetter: (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        },
    }
};
</script>
<style lang="scss">
.card-subtitle {
    text-transform: capitalize;
}
.card-title {
    padding-top:10px;
}
.employee_address{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell,
    "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
}
</style>
